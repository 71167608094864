.parallax_section_wrapper {
 height: 60vh;
 width: 100%;
 z-index: -1;
}

.parallax_section_button_wrapper {
 background: transparent;
 position: absolute;
 top: 30%;
 left: 80%;
 transform: translate(-50%-50%);
 width: 15em;
 div {
  display: inline;
 }
 img {
  width: 1.6em;
  height: 1.6em;
  margin-bottom: 0.01em;
  margin-right: 0.2em;
 }
}
