.multistep_form_wrapper {
 max-width: 900px;
 background: radial-gradient(
  circle,
  rgba(112, 112, 112, 1) 0%,
  rgba(85, 85, 85, 1) 35%,
  rgba(72, 72, 72, 1) 100%
 );
 min-height: 100vh;
}
