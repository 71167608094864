@import '../../../node_modules/bootstrap/scss/_functions.scss';
@import '../../../node_modules/bootstrap/scss/variables.scss';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints.scss';

.wrapper {
 display: flex;
 align-items: center;
 margin: auto;
 @include media-breakpoint-up(sm) {
  margin: auto;
 }
 div {
  margin: auto 0.1rem;
  @include media-breakpoint-up(sm) {
   margin: auto 20px;
  }
 }
}

.dnd_container {
 margin: auto 0;
 display: flex;
 flex-direction: column;
 text-align: center;
 border: 5px solid black;
 border-radius: 20px;
 background: radial-gradient(
  circle,
  rgba(131, 40, 8, 1) 0%,
  rgba(23, 6, 0, 1) 100%
 );
 height: 29rem;
 width: 10rem;
 padding: 1rem 0;
 @include media-breakpoint-up(sm) {
  height: 32rem;
  width: 20rem;
  padding: 5rem auto;
  border-radius: 50px;
  border: 10px solid black;
 }
 div {
  margin: 0.3rem auto;
 }
 h2 {
  margin: 0.225rem auto;
  font-size: 1rem;
  @include media-breakpoint-up(sm) {
   margin: 0.625rem auto;
   font-size: 2rem;
  }
 }
}

.dnd_item {
 border: 1px solid black;
 display: inline-block;
 background: radial-gradient(
  circle,
  rgba(227, 143, 36, 1) 0%,
  rgba(159, 49, 9, 1) 100%
 );
 margin: auto;
 cursor: move;
 width: 100%;
 padding: auto 0.1rem;
 border-radius: 5px;
 height: 4.5em;
 @include media-breakpoint-up(sm) {
  border-radius: 10px;
  padding: auto 1rem;
  height: 5.5em;
 }
 div {
  display: inline-block;
  margin: 0.7rem 0.1rem;
  @include media-breakpoint-up(sm) {
   margin: 0.8rem 0.3125rem;
  }
 }
 img {
  width: 1.725rem;
  height: 1.725rem;
  @include media-breakpoint-up(sm) {
   width: 3.125rem;
   height: 3.125rem;
  }
 }
 span {
  color: white;
  font-size: 1.1rem;
  @include media-breakpoint-up(sm) {
   font-size: 1.8rem;
  }
 }
}

.dnd_navigation_helper_arrow {
 text-align: center;
 margin: auto auto auto 30px;
 display: none;
 @include media-breakpoint-up(md) {
  display: inline;
 }
 img {
  width: 3.5rem;
  height: 3.5rem;
 }
}

.dnd_close_button {
 margin: auto;
 padding: auto;
 background-color: white;
 width: 4rem;
 height: 3rem;
}
