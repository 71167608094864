.main_section_button_wrapper {
 align-self: center;
 margin: auto;
 display: inline-block;
 vertical-align: middle;
}

.main_section_button_icon {
 width: 1.6em;
 height: 1.6em;
 padding: 0.1em;
 margin-bottom: 0.1em;
}
