@import '../styles/theme.module.scss';

.menu {
 margin: auto;
 ul {
  margin: 10px auto;
  padding: 10px;
  li {
   background-color: $main_color;
   color: $text_color;
   span {
    font-size: 1.5rem;
   }
   p {
    font-size: 2.5rem;
   }
  }
 }
}

.dish_title {
 margin: 0 10px;
 float: left;
 text-align: left;
}

.dish_price {
 margin: 0 10px;
 float: right;
}
