.image-aspect-ratio {
 position: relative;
 width: 100%;
 max-width: 400px;
 margin: auto;
}

.image-aspect-ratio .inner {
 position: relative;
}

.img-ratio-1-1 .inner {
 padding-bottom: 100%;
}

.img-ratio-3-2 .inner {
 padding-bottom: 66.66%;
}

.img-ratio-16-9 .inner {
 padding-bottom: 56.25%;
}

.image-aspect-ratio .inner img {
 display: block;
 width: 100%;
 height: 100%;
 max-width: none;
 max-height: none;
 object-fit: cover;
 object-position: 50% 50%;
 position: absolute;
 top: 0;
 left: 0;
}

.inner img {
 display: block;
 margin-left: auto;
 margin-right: auto;
}
