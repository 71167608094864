.welcome_section_wrapper {
 margin: 0 auto;
}

.welcome_section_text_wrapper {
 max-width: 400px;
 margin: 20px;
}

.welcome_section_image {
 max-width: 320px;
 height: 320px;
 display: block;
}
