.example_buttons {
 padding-top: 1rem;
 margin: auto;
 display: flex;
 justify-content: center;
 padding-bottom: 3rem;
}

.btn-lg {
 height: 70px;
 font-size: 2rem;
 margin: 30px 20px auto;
}
